import React, {useState, useEffect} from 'react';

import {
	Autocomplete,
	Box,
	Button,
	TextField,
	Typography,
} from '@mui/material';

import type Org from '../../common/types/Org';
import useApiContext from '../ApiContext';
import type Expertise from '../../common/types/Expertise';
import type Country from '../../common/types/Country';
import type Topic from '../../common/types/Topic';

import ImageInput from './ImageInput';
import type {Founder, OrgFormEditData} from '../../common/types/Org';
import CityChooser from '../components/CityChooser';
import {type CityWithCountryName} from '../../common/types/City';
import Loader from './Loader';
import createArrayInputComponent from './ArrayInput';

const FoundersArray = createArrayInputComponent<Founder>({
	getItemId: founder => founder.id,
	getItemLabel: founder => founder.name,
	updateItem: founder => name => ({...founder, name: name as string}),
	createItem: (id, name) => ({id, name: name as string}),
});

export const OrgForm: React.FC<{
	title?: string;
	buttonText?: string;
	subtitle?: string;
	org: Org;
	handleSubmit: (org: OrgFormEditData) => void;
	extraButtons?: React.ReactElement[];
	orgIsLoading: boolean;
}> = ({
	org,
	handleSubmit,
	buttonText,
	title,
	subtitle,
	extraButtons,
	orgIsLoading,
}) => {
	const [name, setName] = useState(org.name);
	const [description, setDescription] = useState(org.description);
	const [topics, setTopics] = useState<Topic[]>(org.topics ?? []);
	const [expertise, setExpertise] = useState<Expertise[]>(org.expertise ?? []);
	const [countries, setCountries] = useState<Country[]>(org.countries ?? []);
	const [imageBase64, setImageBase64] = useState<string>();
	const [selectedCities, setSelectedCities] = useState<CityWithCountryName[]>([]);
	const {api} = useApiContext();

	const [orgType, setOrgType] = useState<string>('');
	const [founders, setFounders] = useState<Founder[]>([]);
	const [yearFounded, setYearFounded] = useState<Date>(new Date());
	const [annualBudget, setAnnualBudget] = useState<string>('');
	const [website, setWebsite] = useState<string>('');
	const [businessRegistrationLink, setBusinessRegistrationLink] = useState<string>('');

	const [availableTopics, setAvailableTopics] = useState<Topic[]>([]);
	const [availableExpertise, setAvailableExpertise] = useState<Expertise[]>([]);
	const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
	const [dataIsLoading, setDataIsLoading] = useState(false);

	useEffect(() => {
		setName(org.name);
		setDescription(org.description);
		setTopics(org.topics ?? []);
		setExpertise(org.expertise ?? []);
		setCountries(org.countries ?? []);
		if (org.cities) {
			setSelectedCities(
				org.cities.map(c => ({
					...c.city,
					id: c.city.id!,
					countryName: c.city.country!.name,
				})),
			);
		}

		setOrgType(org.orgType ?? '');
		setFounders(org.founders ?? []);
		setYearFounded(new Date(org.yearFounded ?? new Date()));
		setAnnualBudget(org.annualBudget ?? '');
		setWebsite(org.website ?? '');
		setBusinessRegistrationLink(org.businessRegistrationLink ?? '');
	}, [org]);

	useEffect(() => {
		(async () => {
			setDataIsLoading(true);

			const dbTopicsP = api.getTopics(undefined);
			const dbExpertiseP = api.getExpertiseList(undefined);
			const dbCountriesP = api.getCountries(undefined);

			const [dbTopics, dbExpertise, dbCountries] = await Promise.all([
				dbTopicsP,
				dbExpertiseP,
				dbCountriesP,
			]);

			setAvailableTopics(dbTopics);
			setAvailableExpertise(dbExpertise);
			setAvailableCountries(dbCountries);

			setDataIsLoading(false);
		})();
	}, []);

	const onSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		const updatedOrg: OrgFormEditData = {
			name,
			description,
			topics,
			expertise,
			countries,
			imageBase64,
			citiesPayload: selectedCities,
			orgType,
			founders,
			yearFounded,
			annualBudget,
			website,
			businessRegistrationLink,
		};

		handleSubmit(updatedOrg);
	};

	if (dataIsLoading || orgIsLoading) {
		return <Loader />;
	}

	return (
		<Box component='form' onSubmit={onSubmit}>
			{title && (<Typography variant='h6'>
				{title}
			</Typography>)}
			{subtitle && (
				<Typography variant='body1'>
					{subtitle}
				</Typography>
			)}
			<Typography variant='h6'>
				<strong>Organisation Name</strong>
			</Typography>
			<TextField
				label='Official Name'
				value={name}
				onChange={e => {
					setName(e.target.value);
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Description</strong>
			</Typography>
			<TextField
				label='Short business description'
				placeholder='A quick description of your organisation and what makes it special'
				multiline
				fullWidth
				sx={{
					mt: 2,
					mb: 4,
				}}
				value={description}
				onChange={e => {
					setDescription(e.target.value);
				}}
			/>
			<Typography variant='h6'>
				<strong>Website</strong>
			</Typography>
			<TextField
				required
				label='Official website'
				helperText='Paste the URL complete with "https://'
				value={website}
				onChange={e => {
					setWebsite(e.target.value);
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Business Registration</strong>
			</Typography>
			<TextField
				label='Business registration link'
				value={businessRegistrationLink}
				helperText='Paste the URL complete with "https://'
				onChange={e => {
					setBusinessRegistrationLink(e.target.value);
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Logo</strong>
			</Typography>
			<ImageInput
				label='Organization logo or illustration'
				onImageChange={data => {
					setImageBase64(data);
				}}
				currentImageWebPath={org.image?.original.url}
			/>
			<Typography variant='h6'>
				<strong>Country/ies where present</strong>
			</Typography>
			<Autocomplete
				multiple
				options={availableCountries}
				getOptionLabel={option => option.name}
				value={countries}
				isOptionEqualToValue={(option, value) => option.a3Code === value.a3Code}
				onChange={(_event, value) => {
					setCountries(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Countries'
					/>
				)}
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>City/ies of Headquarter</strong>
			</Typography>
			<CityChooser
				onChange = {cities => {
					setSelectedCities(cities);
				}}
				minCities={1}
				value={selectedCities}
				label='Headquarters'
				textLabel='Headquarters'
				getOptionLabel={city => `${city.name}, ${city.countryName}`}
				sx={{mt: 2, mb: 4}}
				api={api}
			/>
			<Typography variant='h6'>
				<strong>Topic</strong>
			</Typography>
			<Autocomplete
				multiple
				options={availableTopics}
				getOptionLabel={option => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				value={topics}
				onChange={(_event, value) => {
					setTopics(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Topics'
					/>
				)}
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Founders</strong>
			</Typography>
			<FoundersArray
				label={'Name of the founders'}
				items={founders}
				onChange={value => {
					setFounders(value);
				}}
				sx={{mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Expertise</strong>
			</Typography>
			<Autocomplete
				multiple
				options={availableExpertise}
				getOptionLabel={option => option.name}
				isOptionEqualToValue={(option, value) => option.id === value.id}
				value={expertise}
				onChange={(_event, value) => {
					setExpertise(value);
				}}
				renderInput={params => (
					<TextField
						{...params}
						label='Expertise'
					/>
				)}
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Type of Organisation</strong>
			</Typography>
			<TextField
				label='Business type'
				value={orgType}
				onChange={e => {
					setOrgType(e.target.value);
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Budget</strong>
			</Typography>
			<TextField
				label='Annual budget and currency'
				value={annualBudget}
				onChange={e => {
					setAnnualBudget(e.target.value);
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Typography variant='h6'>
				<strong>Foundation Year</strong>
			</Typography>
			<TextField
				label='Date'
				type='date'
				value={yearFounded.toISOString().split('T')[0]}
				onChange={e => {
					setYearFounded(new Date(e.target.value));
				}}
				fullWidth
				sx={{mt: 2, mb: 4}}
			/>
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
				my: 4,
			}}>
				{...(extraButtons ?? [])}
				<Button
					type='submit'
					variant='contained'
					color='primary'
					sx={{borderRadius: '20px'}}
				>
					{buttonText ?? 'Submit Organization'}
				</Button>
			</Box>
		</Box>
	);
};

export default OrgForm;
