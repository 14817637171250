import {isAnyUser, isSuperAdmin, isVerifiedUser} from './lib';
import type AuthFunction from './types/AuthFunction';

export type Route = {
	method: 'GET' | 'POST' | 'PUT' | 'DELETE';
	path: string;
	restrict?: AuthFunction[];
};

export const expertise: Route = {
	method: 'GET',
	path: '/api/expertise',
};

export const country: Route = {
	method: 'GET',
	path: '/api/country',
};

export const filterOrganizations: Route = {
	method: 'POST',
	path: '/api/filter-organizations',
	restrict: [isVerifiedUser],
};

export const createUser: Route = {
	method: 'POST',
	path: '/api/users',
};

export const verifyEmail: Route = {
	method: 'GET',
	path: '/api/verify-email',
};

export const login: Route = {
	method: 'POST',
	path: '/api/login',
};

export const getOrganization: Route = {
	method: 'GET',
	path: '/api/orgs/:id',
	restrict: [isVerifiedUser],
};

export const createOrUpdateOrg: Route = {
	method: 'PUT',
	path: '/api/org',
	restrict: [isAnyUser],
};

export const deleteOrg: Route = {
	method: 'DELETE',
	path: '/api/orgs/:id',
	restrict: [isSuperAdmin],
};

export const getCoops: Route = {
	method: 'GET',
	path: '/api/coops',
	restrict: [isVerifiedUser],
};

export const createCoop: Route = {
	method: 'POST',
	path: '/api/coops',
	restrict: [isSuperAdmin],
};

export const getRegions: Route = {
	method: 'GET',
	path: '/api/regions',
};

export const getCountriesWithRegion: Route = {
	method: 'GET',
	path: '/api/countries-with-region',
};

export const getTopics: Route = {
	method: 'GET',
	path: '/api/topics',
};

export const getProductTypes: Route = {
	method: 'GET',
	path: '/api/product-types',
};

export const createOrder: Route = {
	method: 'POST',
	path: '/api/requests',
	restrict: [isVerifiedUser],
};

export const resetPasswordSendEmail: Route = {
	method: 'POST',
	path: '/api/reset-password-send-email',
};

export const resetPasswordSetNew: Route = {
	method: 'POST',
	path: '/api/reset-password-set-new',
};

export const getLanguages: Route = {
	method: 'GET',
	path: '/api/languages',
};

export const createOrUpdateExpertProfile: Route = {
	method: 'POST',
	path: '/api/expert-profile',
	restrict: [isAnyUser],
};

export const getUser: Route = {
	method: 'GET',
	path: '/api/user',
	restrict: [isAnyUser],
};

export const downloadAttachment: Route = {
	method: 'GET',
	path: '/api/download-attachment',
};

export const toggleExpertMembership: Route = {
	method: 'POST',
	path: '/api/toggle-expert-membership',
	restrict: [isAnyUser],
};

export const findCitiesLike: Route = {
	method: 'GET',
	path: '/api/find-cities-like',
	restrict: [isAnyUser],
};

export const getOrgs: Route = {
	method: 'GET',
	path: '/api/my-orgs',
	restrict: [isAnyUser],
};

export const getAffiliatedOrgs: Route = {
	method: 'GET',
	path: '/api/affiliated-orgs',
	restrict: [isAnyUser],
};

export const sendEmailVerificationLink: Route = {
	method: 'POST',
	path: '/api/send-email-verification-link',
};

export const getOrgTopicsAndSubtopics: Route = {
	method: 'GET',
	path: '/api/org-topics-and-subtopics',
	restrict: [isAnyUser],
};

export const getOrgLanguages: Route = {
	method: 'GET',
	path: '/api/org-languages',
	restrict: [isAnyUser],
};
