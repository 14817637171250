import React, {useState, useEffect} from 'react';
import {useParams, Link as RouterLink} from 'react-router-dom';
import {toast} from 'react-toastify';

import Stack from '@mui/material/Stack';

import {
	Box,
	CircularProgress,
	Typography,
	Link,
	Button,
	Grid,
	type ButtonProps,
	type GridProps,
	type TypographyProps,
	Avatar,
} from '@mui/material';

import {
/* 	ContactMail as ContactMailIcon,
	Flag as FlagIcon,
	Language as LanguageIcon, */
	CorporateFare as OrgIcon,
	Web as WebIcon,
	LinkedIn as LinkedInIcon,
	type SvgIconComponent,
} from '@mui/icons-material';

import useApiContext from '../ApiContext';
import useLayout, {type Layout} from '../hooks/useLayout';

import {titleMb, createChevronTitle} from '../jsxUtil';

import Image from '../components/Image';
import type User from '../../common/types/User';
import type Topic from '../../common/types/Topic';
import {buildSubTopicsMap} from '../../common/lib';
import type Org from '../../common/types/Org';

import type GetOrgsResult from '../../common/types/GetOrgsResult';

import ListWithSubtitles, {type Item} from '../components/ListWithSubtitles';
import {showFirstCity} from '../../common/util';

const IconLine: React.FC<{
	icon: SvgIconComponent;
	element: string | React.ReactElement;
}> = ({icon: IconComponent, element}) => (
	<Box sx={{
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		gap: 1,
	}}>
		<IconComponent color='primary' fontSize='medium' />
		{(typeof element === 'string') && <Typography sx={{m: 0, lineHeight: 0}} fontSize='medium'>
			{element}
		</Typography>}
		{(typeof element !== 'string') && element}
	</Box>
);

const SmallTitle: React.FC<{
	title: string;
	sx?: TypographyProps;
	color?: string;
}> = ({title, sx, color}) => (
	<Typography variant='h4' sx={{mb: 1, ...sx}} fontSize='small' color={color}>
		{title}
	</Typography>
);

const List: React.FC<{
	labels: string[];
}> = ({labels}) => (
	<Box sx={{
		display: 'flex',
		flexDirection: 'column',
		gap: 0,
		flexWrap: 'wrap',
	}}>
		{labels.map(label => (
			<Typography
				key={label}
				variant='h6'
			>
				<strong>
					{label}
				</strong>
			</Typography>
		))}
	</Box>
);

export const ExpertProfilePage: React.FC = () => {
	const {id: rawId} = useParams();
	// This is the id of the user we're visiting the profile of
	const userId = (Number(rawId));

	// Note: connectedUserData is the user visiting the page
	const {api, connectedUserData} = useApiContext();
	const [myOrg, setMyOrg] = useState<Org>();

	// Note: profIleUser is the profile of the expert we're visiting the page of
	const [profileUser, setProfileUser] = useState<User>();
	const [topics, setTopics] = useState<Topic[]>([]);

	const [profileOrgs, setProfileOrgs] = useState<GetOrgsResult>();

	const profile = profileUser?.expertProfile;

	useEffect(() => {
		if (!userId) {
			return;
		}

		(async () => {
			try {
				console.log('Getting expert profile from API', {userId});
				const user = await api.getUser({id: userId});

				if (!user.expertProfile) {
					return;
				}

				setProfileUser(user);
			} catch (error) {
				const message = error instanceof Error
					? error.message
					: 'An error occurred while fetching the expert profile';

				console.error(message, {error});
				toast.error(message);
			}
		})();
	}, [userId]);

	useEffect(() => {
		if (!connectedUserData) {
			return;
		}

		(async () => {
			const {myOrg} = await api.getOrgs({
				userId,
			});
			setMyOrg(myOrg);
		})();
	}, [userId]);

	useEffect(() => {
		(async () => {
			const allTopics = await api.getTopics(undefined);
			setTopics(allTopics);
		})();
	}, []);

	useEffect(() => {
		(async () => {
			const orgs = await api.getOrgs({
				userId,
			});
			setProfileOrgs(orgs);
		})();
	}, [userId]);

	const subTopicsMap = buildSubTopicsMap(profile?.subTopics ?? {});

	const makeLayout = (): Layout => {
		if (!profile) {
			return {
				type: 'wide',
				title: undefined,
			};
		}

		const title = (
			<Box sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				gap: 2,
				mb: titleMb,
			}}>
				<Image
					sources={profile.image}
					alt={profile.fullName}
					style={{height: '70px'}}
					defaultElement={
						<Avatar variant='square' sx={{height: '70px', width: '70px'}}>
							{profile.fullName.substring(0, 4)}
						</Avatar>
					}
				/>
				<Box sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 1,
					mb: 0,
				}}>
					{createChevronTitle(profile.fullName, {mb: 0})}
					<Stack direction='row' alignItems='center' gap={1}>
						<OrgIcon color='primary'/>
						{profileOrgs?.myOrg?.cities?.[0] && (
							<Typography color='primary'>
								<strong>{profileOrgs.myOrg.name}</strong>&nbsp;-&nbsp;{
									showFirstCity(profileOrgs.myOrg)
								}
							</Typography>
						)}
					</Stack>
				</Box>
			</Box>
		);

		return {
			type: 'wide',
			title,
		};
	};

	const setLayout = useLayout(makeLayout());

	useEffect(() => {
		setLayout(makeLayout());
	}, [profile]);

	if (!connectedUserData || !profile) {
		return (
			<Box sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100%',
			}}>
				<CircularProgress />
			</Box>
		);
	}

	const expertBelongsToMyOrg = () => {
		if (!connectedUserData) {
			return false;
		}

		if (!myOrg) {
			return false;
		}

		const {members: membersOfMyOrg} = myOrg;

		if (!membersOfMyOrg) {
			return false;
		}

		return membersOfMyOrg.some(member =>
			member.user.id === userId,
		);
	};

	const belongsToMyOrg = expertBelongsToMyOrg();

	const toggleExpertMembership = async () => {
		if (myOrg === undefined) {
			return;
		}

		const {
			updatedExpertOrgs,
			updatedOrgMembers,
		} = await api.toggleExpertMembership(
			{userId, orgId: myOrg.id},
		);

		setProfileUser({
			...profileUser,
			orgs: updatedExpertOrgs,
		});

		setMyOrg({
			...myOrg,
			members: updatedOrgMembers,
		});
	};

	const threeColumnsSectionProps: GridProps = {
		container: true,
		spacing: {
			xs: 4,
			sm: 2,
		},
	};

	const gridColumnProps: GridProps = {
		item: true,
		xs: 12,
		sm: 4,
	};

	const columnContentsStyle = {
		display: 'flex',
		flexDirection: 'column',
		gap: 2,
		width: {
			xs: 'fit-content',
			sm: 'auto',
		},
	};

	const buttonProps: ButtonProps = {
		variant: 'contained',
		color: 'primary',
		disabled: true,
		sx: {
			flex: '1 1 0',
			minWidth: 0,
		},
	};

	const ui = (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 6,
		}}>
			<Grid {...threeColumnsSectionProps}>
				<Grid {...gridColumnProps}>
					<Box sx={{paddingBottom: '2em'}}>
						<SmallTitle title='ORGANIZATIONS' />
						{profileOrgs?.orgs && (
							<ListWithSubtitles
								items={[profileOrgs.myOrg, ...profileOrgs.orgs].filter(
									(org): org is Org => {
										if (org === undefined) {
											return false;
										}

										const {cities} = org;

										if (!cities) {
											return false;
										}

										if (cities.length === 0) {
											return false;
										}

										return true;
									},
								).map((org: Org): Item => {
									const {cities} = org;

									const [firstCity] = cities!;

									const item: Item = {
										key: org.id!,
										title: (
											<Typography variant='h6' color='primary'>
												<RouterLink
													to={`/orgs/${org.id!}`}
													style={{
														color: 'inherit',
													}}
												>
													<strong>{org.name}</strong>
												</RouterLink>
											</Typography>
										),
										subtitle: (
											<Typography variant='body2' color='primary'>
												{firstCity.city.name},&nbsp;{firstCity.city.country!.name}
											</Typography>
										),
									};

									return item;
								})}
							/>
						)}
					</Box>
					<Box sx={{paddingBottom: '2em'}}>
						<SmallTitle title='WORKING IN' />
						<List labels={profile.cities.map(c => `${c.city.name}, ${c.city.country!.name} `)}></List>
					</Box>
					<Box sx={{paddingBottom: '2em'}}>
						<SmallTitle title='COUNTRIES OF FOCUS' />
						<List labels={profile.countries.sort((a, b) => a.rank > b.rank ? 1 : 0).map(c => `${c.country.name} `)}></List>
					</Box>
					<Box sx={{paddingBottom: '2em'}}>
						<SmallTitle title='LANGUAGES' />
						<List labels={profile.languages.slice(1).map(lang => lang.name)}></List>
					</Box>
				</Grid>
				<Grid {...gridColumnProps}>
					<Box sx={{paddingBottom: '2em'}}>
						<SmallTitle title='EXPERTISE' />
						<List labels={profile.areasOfExpertise.map(e => e.name)}></List>
					</Box>

					{topics.map(topic => {
						const subTopics = subTopicsMap.get(topic.id!);

						if (!subTopics || subTopics.length === 0) {
							return null;
						}

						return (
							<Box key={topic.id} sx={{paddingBottom: '2em'}}>
								<Grid {...gridColumnProps}>
									<SmallTitle title='TOPICS AND KNOWLEDGE' />
									<Typography variant='h6'><strong><u>{topic.name}</u></strong></Typography>
									<Box>
										{subTopics.map(st => (
											<Typography
												key={st.id}
												sx={{
													ml: 2,
													mt: 1,
												}}
											>
												•&nbsp;{st.description}
											</Typography>
										))}
									</Box>
								</Grid>
							</Box>
						);
					})}
				</Grid>
				<Grid {...gridColumnProps}>
					<Box sx={{
						...columnContentsStyle,
						alignSelf: {
							xs: 'center',
							sm: 'initial',
						},
						width: 'fit-content',
					}}>
						{profile.bio && (
							<Box sx={{mb: 1}}>
								<SmallTitle title='BIO' />
								<Typography component='div' style={{width: '80%'}}>
									{profile.bio}
								</Typography>
							</Box>
						)}
						{profile.website && (
							<IconLine icon={WebIcon} element={
								<Link href={profile.website} target='_blank' rel='noreferrer'>Website</Link>
							} />
						)}
						{profile.linkedInProfile && (
							<IconLine icon={LinkedInIcon} element={
								<Link href={profile.linkedInProfile} target='_blank' rel='noreferrer'>
									{profile.fullName || 'LinkedIn'}
								</Link>
							} />
						)}
						{belongsToMyOrg && (<Button
							{...buttonProps}
							disabled={false}
							onClick={toggleExpertMembership}
							sx={{
								mt: 2,
								borderRadius: '20px',
								maxWidth: '60%',
							}}
						>
								Remove from your organization
						</Button>)}
						{!belongsToMyOrg && (<Button
							{...buttonProps}
							disabled={!myOrg}
							onClick={toggleExpertMembership}
							sx={{borderRadius: '20px'}}
						>
							{myOrg && 'Add to your organization'}
							{!myOrg && 'Cannot add user, you do not have an organization'}
						</Button>)}
						<Button {...buttonProps}
							sx={{
								borderRadius: '20px',
								maxWidth: '60%',
							}}>
								Send a request
						</Button>
						{profileUser?.email && profileUser.emailVerified && (
							<Button {...buttonProps}
								disabled={false}
								href={`mailto:${profileUser.email}`}
								variant='outlined'
								sx={{
									borderRadius: '20px',
									maxWidth: '60%',
								}}>
									Contact
							</Button>
						)}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);

	return ui;
};

ExpertProfilePage.displayName = 'ExpertProfilePage';

export default ExpertProfilePage;
