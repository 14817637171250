import type Model from './Model';
import type Country from './Country';

import type Expertise from './Expertise';
import type Topic from './Topic';
import type ImageSourceList from './ImageSourceList';
import type User from './User';
import type OrgToUser from './OrgToUser';
import type City from './City';
import type Language from './Language';

export interface OrgToCity {
	id?: number;
	rank: number;
	city: City;
}

export type Founder = {id: number; name: string};
export interface Org extends Model {
	name: string;
	description: string;
	expertise?: Expertise[];
	countries?: Country[];
	languages?: Language[];
	topics?: Topic[];
	image?: ImageSourceList;
	owner?: User;
	members?: OrgToUser[];
	cities?: OrgToCity[];
	orgType?: string;
	founders?: Founder[];
	yearFounded?: Date;
	annualBudget?: string;
	website?: string;
	businessRegistrationLink?: string;
	affiliatedOrgs?: Org[];
}

export interface OrgFormData extends Partial<Org> {
	imageBase64?: string;
	citiesPayload?: City[];
}

export interface OrgFormEditData extends Partial<OrgFormData> {
	id?: number;
}

export const createOrg = (): Org => ({
	id: 0,
	name: '',
	description: '',
	topics: [],
	expertise: [],
	countries: [],
	createdAt: new Date(),
	updatedAt: new Date(),
	members: [],
	cities: [],
});

export default Org;
