import React from 'react';
import {useNavigate} from 'react-router-dom';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {Box, Badge, Typography} from '@mui/material';

import useCart from '../CartContext';

export const CartWidget: React.FC = () => {
	const {products} = useCart();
	const navigate = useNavigate();

	const ui = (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
			}}
			onClick={() => {
				navigate('/requests');
			}}
		>
			<Badge badgeContent={products.length} color='primary' sx={{mr: 1, pl: '10px'}}>
				<ShoppingCartIcon/>
			</Badge>
			<Typography sx={{
				display: {
					xs: 'none',
					sm: 'block',
				},
				paddingRight: '20px',
				paddingLeft: 1,
			}}>
				Your requests
			</Typography>
		</Box>
	);

	return ui;
};

export default CartWidget;
