import type AuthFunction from './types/AuthFunction';
import RoleType from './types/RoleType';
import {type SubTopicObjectMap, type SubTopicMap} from './types/SubTopic';

export const isSuperAdmin: AuthFunction = roles => roles.map(r => r.roleType).includes(RoleType.superAdmin);

export const isVerifiedUser: AuthFunction = roles => {
	const res = roles.map(r => r.roleType).includes(RoleType.user);

	return res;
};

export const isAnyUser: AuthFunction = () => true;

export const buildSubTopicsMap = (subTopics: SubTopicObjectMap): SubTopicMap =>
	new Map(
		Object.entries(subTopics).map(([key, value]) => [parseInt(key, 10), value]),
	);
