import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import useLayout from '../hooks/useLayout';
import useApiContext from '../ApiContext';

export const Home: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
	});

	const {connectedUserData} = useApiContext();

	const navigate = useNavigate();

	useEffect(() => {
		if (connectedUserData) {
			navigate('/orgs');
			return;
		}

		navigate('/login');
	}, [connectedUserData, navigate]);

	return <div />;
};

export default Home;
