import React, {useEffect} from 'react';
import {useLoaderData, useSearchParams, Link} from 'react-router-dom';

import {type Api} from '../createApi';

import OrgCard from '../components/OrgCard';
import OrgFilter from '../components/OrgFilter';
import type OrgsQuery from '../../common/types/OrgsQuery';
import {
	type Org,
} from '../../common/types/Org';

import {
	Box,
	Grid,
	Typography,
	Stack,
} from '@mui/material';

import type OrgsReply from '../../common/types/OrgsReply';

import {createChevronTitle} from '../jsxUtil';
import useLayout from '../hooks/useLayout';
import useApiContext from '../ApiContext';

import RoleType from '../../common/types/RoleType';
import {toast} from 'react-toastify';

import FilterAltIcon from '@mui/icons-material/FilterAlt';

type Props = {
	reply: OrgsReply;
	initialQuery: OrgsQuery;
};

// eslint-disable-next-line @typescript-eslint/ban-types
const intList = (str: string | null): number[] => {
	if (!str) {
		return [];
	}

	const parsed = str.split(',').map(Number);

	if (parsed.some(isNaN)) {
		return [];
	}

	return parsed;
};

export const createLoader = (api: Api) => async ({request}: {request: Request}): Promise<Props> => {
	const url = new URL(request.url);
	const params = url.searchParams;

	const query = {
		expertiseId: intList(params.get('eid')),
		countryId: intList(params.get('cid')),
		topicId: intList(params.get('tid')),
	};

	return {
		reply: api.userIsVerified() ? await api.filterOrgs(query) : {
			expertise: [],
			country: [],
			topic: [],
			orgs: [],
		},
		initialQuery: query,
	};
};

export const OrgsList: React.FC = () => {
	const title = createChevronTitle('Global Expertise Hub');

	const {connectedUserData, api} = useApiContext();

	useLayout({
		type: 'three-column',
		title,
		left: undefined,
	});

	const {
		reply: {orgs, expertise, country, topic},
		initialQuery,
	} = useLoaderData() as Props;

	const [orgsList, setOrgsList] = React.useState<Org[]>(orgs);

	const [, setSearchParams] = useSearchParams();

	useEffect(() => {
		setOrgsList(orgs);
	}, [orgs]);

	const onChange = (query: OrgsQuery) => {
		setSearchParams({
			eid: query.expertiseId.join(','),
			cid: query.countryId.join(','),
			tid: query.topicId.join(','),
		});
	};

	const filter = (
		<OrgFilter {...{expertise, country, topic, onChange, initialQuery}}/>
	);

	if (!connectedUserData) {
		return (
			<Box>
				{filter}
				<Typography variant='h5'>
					You need to&nbsp;
					<Typography component='p'color='primary'>
						<Link
							style={{
								color: 'inherit',
								fontFamily: 'inherit',
								fontSize: 'inherit',
							}}
							to='/login'
						>
							<strong>log&nbsp;in</strong>
						</Link>
					</Typography> to see&nbsp;
					organizations the directory of organizations.
				</Typography>
			</Box>);
	}

	if (connectedUserData && ![RoleType.user, RoleType.superAdmin].some(
		neededRole =>
			(connectedUserData?.roles ?? [])
				.find(userRole => userRole.roleType === neededRole))) {
		return (
			<Box>
				{filter}
				<Typography variant='h5' component='div'>
					You need to&nbsp;
					<Typography variant='h5' component='span'color='primary'>
						<Link
							style={{
								color: 'inherit',
								fontFamily: 'inherit',
								fontSize: 'inherit',
							}}
							to='/login'
							onClick={async () => {
								try {
									await api.sendEmailVerificationLink({
										userId: connectedUserData.id,
									});
									toast.success('Verification email sent, please click the link you received');
								} catch (e) {
									console.error(e);
									toast.error('Failed to send verification email');
								}
							}}
						>
							verify&nbsp;your&nbsp;email
						</Link>
					</Typography> to see&nbsp;
					the directory of organizations.
				</Typography>
			</Box>
		);
	}

	return (
		<Box>
			<Typography>
				Unlock fast, objective insights from a worldwide network of trusted specialists. Make informed decisions confidently, with direct access to advisory services soon available.
			</Typography>
			<Stack alignItems='center' direction='row' gap={2} sx={{my: 3}} >
				<FilterAltIcon fontSize='medium'/>
				<Typography variant='h6'>
					<strong>Search Filters</strong>
				</Typography>
			</Stack>

			{filter}
			<Grid container spacing={4}>
				{orgsList.map(org => (
					<Grid item xs={12} key={org.id}>
						<OrgCard org={org} />
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default OrgsList;
