import React, {useState, useEffect, useCallback} from 'react';
import {unstable_usePrompt as usePrompt} from 'react-router-dom';

import {
	Autocomplete,
	Box,
	Button,
	TextField,
	Typography,
} from '@mui/material';

import {toast} from 'react-toastify';

import pluralize from 'pluralize';

import {createChevronTitle} from '../jsxUtil';

// For reference, widget to adapt for subtopics: import StringArrayInput from '../components/StringArrayInput';
import ImageInput from '../components/ImageInput';
import createArrayInput from '../components/ArrayInput';

import type Language from '../../common/types/Language';
// D import type Country from '../../common/types/Country';
import type Topic from '../../common/types/Topic';
import {type SubTopic, type SubTopicObjectMap} from '../../common/types/SubTopic';
import type Expertise from '../../common/types/Expertise';
import {buildSubTopicsMap} from '../../common/lib';

import useApiContext from '../ApiContext';
import {Link} from 'react-router-dom';

import useLayout from '../hooks/useLayout';
import createUseStateGroup from '../hooks/useStateGroup';
import {countWords} from '../../common/util';
import {type CityWithCountryName} from '../../common/types/City';
import CityChooser from '../components/CityChooser';
import type Country from '../../common/types/Country';

import {maxBioWordCount as maxExpertBioWordCount} from '../../common/types/ExpertProfile';

import useTraceUpdate from '../hooks/useTraceUpdate';
import Loader from '../components/Loader';

const getCityLabel = (city: CityWithCountryName) => `${city.name}, ${city.countryName}`;

const SubTopicsArrayInput = createArrayInput<SubTopic, number>({
	getItemId: item => item.id,
	getItemLabel: item => item.description,
	updateItem: item => newValue => ({
		...item,
		description: newValue as string,
	}),
	createItem(id, description, topicId) {
		if (typeof topicId !== 'number') {
			toast.error('Missing topic id to create subtopic, this is a bug...');
			throw new Error('wrong type for "topicId"');
		}

		const newSubTopic: SubTopic = {
			id,
			description: description as string,
			topicId,
			rank: id,
			createdAt: new Date(),
			updatedAt: new Date(),
		};

		return newSubTopic;
	},
});

export const ExpertProfileForm: React.FC = () => {
	const title = createChevronTitle('Edit Your Profile');

	useLayout({
		type: 'three-column',
		title,
	});

	/* ↓ General purpose variables, probably don't touch ↓ */
	const {api} = useApiContext();
	const [isLoading, setIsLoading] = useState(true);
	const [toastErrorIsOpen, setToastErrorIsOpen] = useState(false);
	/* ↑ End of general purpose variables ↑ */

	/* ↓ Load the fixtures we need, i.e. the data we need from the db ↓ */
	const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
	const [availableCountries, setAvailableCountries] = useState<Country[]>([]);
	const [availableTopics, setAvailableTopics] = useState<Topic[]>([]);
	const [availableExpertise, setAvailableExpertise] = useState<Expertise[]>([]);

	useEffect(() => {
		const loadLanguages = async () => {
			const languages = await api.getLanguages(undefined);
			setAvailableLanguages(languages);
		};

		const loadCountries = async () => {
			const countries = await api.getCountries(undefined);
			setAvailableCountries(countries);
		};

		const loadTopics = async () => {
			const topics = await api.getTopics(undefined);
			setAvailableTopics(topics);
		};

		const loadExpertise = async () => {
			const expertise = await api.getExpertiseList(undefined);
			setAvailableExpertise(expertise);
		};

		const loadingPromises = [
			loadLanguages(),
			loadCountries(),
			loadTopics(),
			loadExpertise(),
		];

		Promise.all(loadingPromises).catch(e => {
			console.error('Failed to load fixtures', e);
		});
	}, []);
	/* ↑ End of fixtures loading ↑ */

	// eslint-disable-next-line @typescript-eslint/ban-types
	const [userIdIfProfileExists, setUserIdIfProfileExists] = useState<number | null>(null);

	const formState = createUseStateGroup();

	/* ↓ Start of Expert Profile fields definition ↓ */
	const [fullName, setFullName] = formState.useState('');
	const [spokenLanguages, setSpokenLanguages] = formState.useState<Language[]>([]);
	const [topics, setTopics] = formState.useState<Topic[]>([]);
	const [subTopics, setSubTopics] = formState.useState<SubTopicObjectMap>({});
	const [expertise, setExpertise] = formState.useState<Expertise[]>([]);
	const [linkedInProfile, setLinkedInProfile] = formState.useState<string>('');
	const [imageBase64, setImageBase64] = formState.useState<string>('');
	const [bio, setBio] = formState.useState('');
	const [website, setWebsite] = formState.useState('');
	const [selectedCities, setSelectedCities] = formState.useState<CityWithCountryName[]>([]);
	const [selectedCountries, setSelectedCountries] = formState.useState<Country[]>([]);
	const [currentImageWebPath, setCurrentImageWebPath] = formState.useState<string>('');
	/* ↑ End of Expert Profile Fields ↑ */

	/*
	console.log({
		selectedCountries,
		selectedCities,
	});
	*/

	/* ↓ Various things ↓ */
	useTraceUpdate({
		fullName,
		spokenLanguages,
		topics,
		subTopics,
		expertise,
		linkedInProfile,
		imageBase64,
		bio,
		website,
		selectedCities,
		selectedCountries,
	});

	const onCitiesChanged = useCallback((cities: CityWithCountryName[]) => {
		setSelectedCities(cities);
	}, [setSelectedCities]);
	/* ↑ End of various things ↑ */

	/* ↓ Validation parameters, you can change these values no problem ↓ */
	const minCountries = 1;
	const minTopics = 1;
	const minSubTopicsPerTopic = 1;
	const maxBioWordCount = maxExpertBioWordCount;
	/* ↑ End of validation parameters ↑ */

	/* ↓ Some variables for the validation logic ↓ */
	const hasSelectedEnoughCountries = selectedCountries.length >= minCountries;
	const notEnoughCountriesMessage = hasSelectedEnoughCountries
		? null
		: `Please select at least ${pluralize('country', minCountries, true)} of focus.`;

	const [bioHelperText, setBioHelperText] = useState('');
	const [bioHasError, setBioHasError] = useState(false);
	/* ↑ End of variables for the validation logic ↑ */

	/* ↓ Some objects needed for the subtopics feature to work ↓ */
	const subTopicsTree = buildSubTopicsMap(subTopics);

	for (const topic of topics) {
		if (!subTopicsTree.has(topic.id!)) {
			subTopicsTree.set(topic.id!, []);
		}
	}

	const hasSelectedTopics = topics.length > 0;
	const hasSelectedEnoughTopics = topics.length >= minTopics;
	/* ↑ End of definition of objects needed for the subtopics feature ↑ */

	/* ↓ Logic to submit the form ↓ */
	const handleSubmit = async (e?: React.FormEvent) => {
		e?.preventDefault();

		if (toastErrorIsOpen) {
			// Do not submit the form if a toast is open, it means there are errors.
			return;
		}

		const errorMessages: Array<{msg: React.ReactNode; key: string}> = [];

		if (bioHasError) {
			errorMessages.push({msg: 'Please correct the bio field before saving.', key: 'bio'});
		}

		if (!hasSelectedEnoughCountries) {
			errorMessages.push({msg: notEnoughCountriesMessage!, key: 'countries'});
		}

		if (!hasSelectedEnoughTopics) {
			errorMessages.push({msg: `Please select at least ${pluralize('subtopic', minTopics, true)} of focus.`, key: 'topics'});
		}

		const subtopicsHaveError = topics.some(topic =>
			(subTopicsTree.get(topic.id!) ?? []).length < minSubTopicsPerTopic,
		);

		if (subtopicsHaveError) {
			errorMessages.push({msg: `Please add at least ${pluralize('subtopic', minSubTopicsPerTopic, true)} for each topic.`, key: 'subtopics'});
		}

		if (errorMessages.length > 0) {
			const [O, I]: React.ElementType[] = errorMessages.length > 1 ? ['ol', 'li'] : ['div', 'div'];

			setToastErrorIsOpen(true);

			toast.error(<div>
				<Typography
					variant='h6'
				>
					Oops, please correct the following {pluralize('error', errorMessages.length, true)}:
				</Typography>
				<O>
					{errorMessages.map(
						(m, idx) => <I key={idx}><p>{m.msg}</p></I>,
					)}
				</O>
			</div>, {
				autoClose: false,
				onClose() {
					setToastErrorIsOpen(false);
				},
			});

			// Do not submit the form if it has errors.
			return;
		}

		try {
			const createdAt = new Date();
			const {profile, userId} = await api.createOrUpdateExpertProfile({
				fullName,
				languages: spokenLanguages,
				topicsOfFocus: topics,
				subTopics,
				areasOfExpertise: expertise,
				linkedInProfile,
				createdAt,
				updatedAt: createdAt,
				imageBase64,
				bio,
				website,
				citiesPayload: selectedCities,
				cities: [],
				countries: selectedCountries.map((c, rank) => ({
					rank,
					country: c,
				})),
			});

			setFullName(profile.fullName);
			setSpokenLanguages(profile.languages);
			setTopics(profile.topicsOfFocus);
			setExpertise(profile.areasOfExpertise);
			setLinkedInProfile(profile.linkedInProfile);

			setUserIdIfProfileExists(userId);

			toast.success('Profile saved successfully!');

			formState.setPristine();
		} catch (e) {
			console.error(e);
			const message = e instanceof Error ? e.message : 'Something went wrong while saving profile...';
			toast.error(message);
		}
	};
	/* ↑ End of logic to submit the form ↑ */

	/* ↓ Load the profile ↓ */
	useEffect(() => {
		(async () => {
			try {
				const user = await api.getUser(undefined);
				if (!user.expertProfile) {
					setUserIdIfProfileExists(null);
					return;
				}

				setUserIdIfProfileExists(user.id!);

				const {expertProfile: profile} = user;

				setFullName(profile.fullName);
				setSpokenLanguages(profile.languages);
				setTopics(profile.topicsOfFocus);
				setExpertise(profile.areasOfExpertise);
				setLinkedInProfile(profile.linkedInProfile);
				setSubTopics(profile.subTopics);
				setBio(profile.bio);
				setWebsite(profile.website);

				/* ↓ Convert City Representation ↓ */
				//* D
				if (profile.cities) {
					setSelectedCities(profile.cities.sort((a, b) => a.rank > b.rank ? 1 : 0).map(c => ({
						...c.city,
						id: c.city.id!,
						countryName: c.city.country!.name,
					})));
				}
				/* ↑ End of loading cities ↑ */

				/* ↓ Convert Country Representation ↓ */
				const expertCountries = profile
					.countries
					.sort((a, b) => a.rank > b.rank ? 1 : 0)
					.map(c => c.country);
				setSelectedCountries(expertCountries);
				/* ↑ End of loading countries ↑ */

				setCurrentImageWebPath(profile?.image?.original.url ?? '');

				formState.setPristine();
			} catch (e) {
				console.error('should not be a real problem', e);
			} finally	{
				setIsLoading(false);
			}
		})();
	}, []);
	/* ↑ End of profile loading ↑ */

	// Prevent accidental navigation if the form has changes
	usePrompt({
		message: 'You have unsaved changes, are you sure you want to leave?',
		when: formState.isDirty(),
	});

	/* ↓ Code to validate the bio field ↓ */
	useEffect(() => {
		const count = countWords(bio);

		if (count > maxBioWordCount) {
			setBioHelperText(`Too many words: ${count} (maximum is ${maxBioWordCount})`);
			setBioHasError(true);
		} else {
			setBioHelperText(`${pluralize('word', count, true)} / maximum ${maxBioWordCount}`);
			setBioHasError(false);
		}
	}, [bio]);
	/* ↑ End of code to validate the bio field ↑ */

	/* ↓ Display a loader while the profile is loading ↓ */
	if (isLoading) {
		return <Loader />;
	}
	/* ↑ End of loader display ↑ */

	/* ↓ Finally the UI ↓ */
	const ui = (
		<Box>
			{toastErrorIsOpen && <Box sx={{
				position: 'fixed',
				top: 0,
				left: 0,
				bottom: 0,
				right: 0,
				backgroundColor: 'rgba(0, 0, 0, 0.3)',
				zIndex: 100,
			}}/>}
			<Typography sx={{mb: 2}} variant='h5'>
				Refine your profile to showcase your expertise and expand your influence within our global network.
			</Typography>
			{userIdIfProfileExists && (
				<Typography sx={{mb: 6}} color='primary'>
					<Link
						to={`/profile/${userIdIfProfileExists}`}
						style={{color: 'inherit', textDecoration: 'underline'}}
					>
						Check out what your profile looks like to others
					</Link>
				</Typography>
			)}

			<Box component='form' onSubmit={handleSubmit}>
				{formState.isDirty() && (
					<Typography sx={{mb: 4}} color='secondary'>
						You have unsaved changes! Please remember to save before leaving this page.
					</Typography>
				)}
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Name and Surname</strong>
				</Typography>
				<TextField
					sx={{
						mb: 4,
					}}
					label='Full name'
					fullWidth
					required
					value={fullName}
					onChange={e => {
						setFullName(e.target.value);
					}}
				/>
				<Typography variant='h6'>
					<strong>Picture</strong>
				</Typography>
				<ImageInput
					label='Profile picture'
					currentImageWebPath={currentImageWebPath}
					onImageChange={base64 => {
						setImageBase64(base64 ?? '');
					}}
					sx={{
						mb: 4,
					}}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Description</strong>
				</Typography>
				<TextField
					label='Short bio'
					placeholder='A quick description of who you are and what you do best'
					multiline
					fullWidth
					sx={{
						mb: 4,
					}}
					value={bio}
					onChange={e => {
						setBio(e.target.value);
					}}
					error={bioHasError}
					helperText={bioHelperText}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Country/ies</strong>
				</Typography>
				<Autocomplete
					multiple
					options={availableCountries}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => value && option && option.id === value.id}
					value={selectedCountries}
					onChange={(_event, value) => {
						setSelectedCountries(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Countries of focus'
							required={topics.length === 0}
						/>
					)}
					sx={{mb: hasSelectedEnoughCountries ? 4 : 0}}
				/>
				{!hasSelectedEnoughCountries && (
					<Typography sx={{mb: 4}} color='error' variant='caption' component='div'>
						{notEnoughCountriesMessage}
					</Typography>
				)}
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>City/ies</strong>
				</Typography>
				<CityChooser
					sx={{mb: 4}}
					minCities={1}
					value={selectedCities}
					label='Cities of focus'
					textLabel='City'
					getOptionLabel={getCityLabel}
					onChange={onCitiesChanged}
					api={api}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Languages</strong>
				</Typography>
				<Autocomplete
					multiple
					options={availableLanguages}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={spokenLanguages}
					onChange={(_event, value) => {
						setSpokenLanguages(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Spoken languages'
							required={spokenLanguages.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Expertise</strong>
				</Typography>

				<Autocomplete
					multiple
					options={availableExpertise}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={expertise}
					onChange={(_event, value) => {
						setExpertise(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Areas of expertise'
							required={expertise.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Topics and Knowledge</strong>
				</Typography>

				<Autocomplete
					multiple
					options={availableTopics}
					getOptionLabel={option => option.name}
					isOptionEqualToValue={(option, value) => option.id === value.id}
					value={topics}
					onChange={(_event, value) => {
						setTopics(value);
					}}
					renderInput={params => (
						<TextField
							{...params}
							label='Topics of focus'
							required={topics.length === 0}
						/>
					)}
					sx={{mb: 4}}
				/>

				{hasSelectedTopics && <Box sx={{
					mb: 4,
				}}>
					<Typography sx={{mb: 1}}>
						Please describe below your specific knowledge or more granular areas of interest
						for each of your topics of focus.
					</Typography>

					<Box sx={{
						borderLeft: 1,
						borderColor: 'divider',
						pl: 4,
					}}>
						{
							topics.map(topic => {
								if (!subTopicsTree.has(topic.id!)) {
									return null;
								}

								return <SubTopicsArrayInput
									key={topic.id}
									extraData={topic.id!}
									label={`Subtopics for ${topic.name}`}
									items={subTopicsTree.get(topic.id!)!}
									onChange={value => {
										const newSubTopics = {
											...subTopics,
											[topic.id!]: value,
										};

										setSubTopics(newSubTopics);
									}}
									sx={{
										p: 0,
										mb: 2,
									}}
								/>;
							})
						}
					</Box>
				</Box>}
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Website</strong>
				</Typography>
				<TextField
					sx={{
						mb: 4,
					}}
					label='Website'
					helperText='Your personal or professional website, be sure to include "https://"'
					fullWidth
					value={website}
					onChange={e => {
						setWebsite(e.target.value);
					}}
				/>
				<Typography variant='h6' sx={{pb: 2}}>
					<strong>Linkedin</strong>
				</Typography>
				<TextField
					sx={{
						mb: 4,
					}}
					label='LinkedIn profile'
					helperText='Paste the entire URL of your profile'
					fullWidth
					value={linkedInProfile}
					onChange={e => {
						setLinkedInProfile(e.target.value);
					}}
				/>

				<Box sx={{mt: 4, textAlign: 'center'}}>
					<Button
						type='submit'
						variant='contained'
						disabled={formState.isPristine() || toastErrorIsOpen}
						sx={{borderRadius: '20px'}}
					>
						Save profile
					</Button>
					{formState.isPristine() && (
						<Typography
							sx={{mt: 1}}
							variant='body2'
						>
							No changes to save
						</Typography>
					)}
				</Box>
			</Box>
		</Box>
	);
	/* ↑ End of the UI construction ↑ */

	// OMG we did it!!!
	return ui;
};

export default ExpertProfileForm;
