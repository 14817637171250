import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, Typography, IconButton, InputBase, Paper} from '@mui/material';
import {Remove as RemoveIcon, Add as AddIcon, Search} from '@mui/icons-material';

import {createFunnelTitleElement} from '../../jsxUtil';
import useApiContext from '../../ApiContext';

import type CountryWithRegion from '../../../common/types/CountryWithRegion';

import useCart from '../../CartContext';
import FunnelRecap from '../../components/FunnelRecap';
import useLayout from '../../hooks/useLayout';

type RegionMap = Map<string, CountryWithRegion[]>;

export const Funnel3Country: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: <FunnelRecap />,
	});

	const cart = useCart();
	const navigate = useNavigate();
	const {api} = useApiContext();

	const [countries, setCountries] = useState<RegionMap>(new Map());
	const [folded, setFolded] = useState<string[]>([]);
	const [search, setSearch] = useState<string>('');

	useEffect(() => {
		(async () => {
			try {
				const countriesFromDb = await api.getCountriesWithRegion(undefined);

				const map: RegionMap = new Map();

				for (const country of countriesFromDb) {
					const {region_name: regionName} = country;

					const cell = map.get(regionName);

					if (cell) {
						cell.push(country);
					} else {
						map.set(regionName, [country]);
					}
				}

				setCountries(map);
			} catch (e) {
				console.error(e);
			}
		})();
	}, []);

	useEffect(() => {
		const regions = Array.from(countries.keys());
		setFolded(regions);
	}, [countries]);

	const countryMatches = (country: CountryWithRegion) => {
		if (country.country_name.toLowerCase().includes(search.toLowerCase())) {
			return true;
		}

		return false;
	};

	const isFolded = (regionName: string) => {
		const regionCountries = countries.get(regionName);

		if (!regionCountries) {
			return false;
		}

		if (search) {
			const matches = regionCountries.some(countryMatches);

			if (matches) {
				return false;
			}
		}

		return folded.includes(regionName);
	};

	const selected = cart.current.country !== undefined;

	const title = createFunnelTitleElement(
		'Choose a country',
		'3/6 Country',
	);

	const handleRegionClick = (regionName: string) => () => {
		if (folded.includes(regionName)) {
			setFolded(folded.filter(f => f !== regionName));
		} else {
			setFolded([...folded, regionName]);
		}
	};

	const regionIcon = (regionName: string) => {
		if (folded.includes(regionName)) {
			return <AddIcon />;
		}

		return <RemoveIcon />;
	};

	const ui = (
		<Box>
			{title}

			{cart.current.country && (
				<Box sx={{mt: 2, mb: 4}}>
					<Typography>
						<strong>Selected Country:</strong> {cart.current.country.country_name}
					</Typography>
				</Box>
			)}

			<Box sx={{
				width: {
					xs: '100%',
					sm: '70%',
				},
			}}>
				<Paper
					component='form'
					sx={{
						display: 'flex',
						alignItems: 'center',
						mb: 4,
						boxShadow: 'none',
						borderRadius: '20px',
						backgroundColor: '#ececec',
					}}
				>
					<InputBase
						sx={{ml: 2, flex: 1}}
						placeholder='Search Country'
						value={search}
						onChange={e => {
							setSearch(e.target.value);
						}}
					/>
					<IconButton type='button' sx={{mr: 1}}>
						<Search />
					</IconButton>
				</Paper>

				<Box sx={{my: 2}}>
					{Array.from(countries).map(([regionName, countries]) => (
						(!search || !isFolded(regionName)) && <Box key={regionName} sx={{my: 2}}>
							<Box
								onClick={handleRegionClick(regionName)}
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
									justifyContent: 'space-between',
									gap: 1,
									cursor: 'pointer',
									userSelect: 'none',
									borderBottom: 1,
									paddingBottom: 1,
								}}
							>
								<Typography variant='body1'><strong>{regionName}</strong></Typography>
								{regionIcon(regionName)}
							</Box>
							{(!isFolded(regionName)) && (
								<Box sx={{
									mt: 2,
								}}>
									{countries.map(country => (
										<Typography
											key={country.country_code}
											variant='body2'
											sx={{
												ml: 4,
												width: 'fit-content',
												fontWeight: (search && countryMatches(country)) ? 'bold' : undefined,
												cursor: 'pointer',
											}}
											color={cart.current.country?.country_code === country.country_code ? 'primary' : undefined}
											onClick={() => {
												cart.setCountry(country);
											}}
										>
											{country.country_name}
										</Typography>
									))}
								</Box>
							)}
						</Box>
					))}
				</Box>
			</Box>

			<Box sx={{
				mt: 4,
				display: 'flex',
				justifyContent: 'flex-end',
				gap: 2,
			}}>
				<Button
					variant='text'
					sx={{
						textDecoration: 'underline',
					}}
					color='secondary'
					onClick={
						() => {
							navigate('/product-length');
						}
					}
				>
					Back
				</Button>
				<Button disabled={!selected} variant='contained' color='secondary' sx={{borderRadius: '20px'}} onClick={
					() => {
						navigate('/topic');
					}
				}>
					Next
				</Button>
			</Box>
		</Box>
	);

	return ui;
};

export default Funnel3Country;
