import {Box} from '@mui/material';
import React from 'react';

export type Item = {
	key: string | number;
	title: React.ReactNode;
	subtitle: React.ReactNode;
};

export const ListWithSubtitles: React.FC<{
	items: Item[];
}> = ({items}) => {
	const ui = (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 1,
		}}>
			{items.map(({key, title, subtitle}) => (
				<Box key={key} sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 0,
				}}>
					{title}
					{subtitle}
				</Box>
			))}
		</Box>
	);

	return ui;
};

export default ListWithSubtitles;
