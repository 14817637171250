import {type SxProps, Box, Autocomplete, TextField, Typography} from '@mui/material';
import pluralize from 'pluralize';
import React, {useState, useEffect, memo} from 'react';
import {type CityWithCountryName} from '../../common/types/City';
import {mergeArrays, getId} from '../../common/util';
import {type Api} from '../createApi';

export type SharedServices = {
	api: Api;
	log?: (...args: unknown[]) => void;
};

export type CityChooserSpecificProps = {
	label: React.ReactNode;
	getOptionLabel: (option: CityWithCountryName) => string;
	textLabel: string;
	value: CityWithCountryName[];
	onChange: (value: CityWithCountryName[]) => void;
	minCities: number;
	sx?: SxProps;
};

export type CityChooserProps = CityChooserSpecificProps & SharedServices;

const PreCityChooser: React.FC<CityChooserProps> = ({
	api,
	value,
	onChange,
	label,
	textLabel,
	getOptionLabel,
	sx,
	log = () => undefined,
}) => {
	const [query, setQuery] = useState('');
	const [available, setAvailable] = useState<CityWithCountryName[]>(value);
	const [selected, setSelected] = useState<CityWithCountryName[]>(value);

	const mergeWithAvailable = mergeArrays<CityWithCountryName>(available);
	const mergeWithSelected = mergeArrays<CityWithCountryName>(selected);

	useEffect(() => {
		setSelected(value);
		setAvailable([...mergeWithAvailable(value)]);
	}, [value]);

	useEffect(() => {
		const fetchResults = async () => {
			log('query changed to:', query);
			const results = await api.findCitiesLike({q: query});

			// Again, we need to merge the selected items with the new available ones
			// because there is no guarantee that the selected items are all in the new available list.
			setAvailable([...mergeWithSelected(results)]);
		};

		fetchResults().catch((error: unknown) => {
			console.error('failed to fetch cities upon query change:', {query, error});
		});
	}, [query]);

	useEffect(() => {
		onChange(selected);
	}, [selected]);

	const handleChange = (_event: unknown, value: CityWithCountryName[]) => {
		setAvailable([...mergeWithAvailable(value)]);
		setSelected(value);
	};

	return (
		<Box sx={sx}>
			<Autocomplete
				multiple
				options={available}
				getOptionLabel={getOptionLabel}
				value={selected}
				onInputChange={(_event, value) => {
					setQuery(value);
				}}
				isOptionEqualToValue={
					(option, value) => getId(option) === getId(value)
				}
				onChange={handleChange}
				renderInput={params => (
					<TextField
						{...params}
						label={label}
						variant='outlined'
						onChange={event => {
							setQuery(event.target.value);
						}}
					/>
				)}
			/>
			<Typography variant='caption'>
				{`Selected ${pluralize(textLabel.toLocaleLowerCase(), selected.length, true)}`}
			</Typography>
		</Box>
	);
};

export const CityChooser = memo(PreCityChooser);

export default CityChooser;
