import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Box, Typography} from '@mui/material';

import {createChevronTitle} from '../jsxUtil';
import useLayout from '../hooks/useLayout';
import OrgForm from '../components/OrgForm';
import {type Org, type OrgFormData, createOrg as createOrgSkeleton} from '../../common/types/Org';
import {getMessage} from '../../common/util';
import useApiContext from '../ApiContext';
import type User from '../../common/types/User';

const CreateOrEditOrgForm: React.FC = () => {
	const title = createChevronTitle('Edit Your Organization');

	useLayout({
		type: 'three-column',
		title,
		left: undefined,
	});

	const {api, connectedUserData} = useApiContext();

	const [org, setOrg] = React.useState<Org>(createOrgSkeleton());
	const [user, setUser] = React.useState<User>();
	const [isNew, setIsNew] = React.useState(true);

	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		if (!connectedUserData) {
			return;
		}

		(async () => {
			setLoading(true);
			const user = await api.getUser(connectedUserData);
			setUser(user);
			setLoading(false);
			if (user.org) {
				setOrg(user.org);
			}

			if (user.org?.id) {
				setIsNew(false);
			}
		})();
	}, [connectedUserData]);

	const handleSubmit = async (org: OrgFormData) => {
		try {
			const savedOrg = await api.createOrUpdateOrg(org);

			setOrg(savedOrg);
			toast.success(
				isNew
					? 'Organization created'
					: 'Organization updated',
			);
		} catch (e) {
			toast.error('Failed to create organization: ' + getMessage(e, 'unknown error'));
		}
	};

	const ui = (
		<Box>
			<Typography sx={{mb: 2}} variant='h5'>
				Adding thorough information about your organization helps us connect you with institutions and opportunities that align with your values.
			</Typography>

			{user?.org?.id && (
				<Typography sx={{mb: 6}} color='primary'>
					<Link
						to={`/orgs/${user.org.id}`}
						style={{color: 'inherit', textDecoration: 'underline'}}
					>
						Check out what your organization page looks like to others
					</Link>
				</Typography>
			)}

			<OrgForm
				org={org}
				handleSubmit={handleSubmit}
				title={undefined}
				orgIsLoading={loading}
				buttonText={
					org.id
						? 'Save Changes'
						: 'Create Organization'
				}
			/>
		</Box>
	);

	return ui;
};

export default CreateOrEditOrgForm;
