import {createContext, useContext} from 'react';

import type ApiContextType from './types/ApiContextType';
import createApi from './createApi';

import config from '../../config.client';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const ApiContext = createContext<ApiContextType>({
	api: createApi(config),
	connectedUserData: undefined,
});

export const useApiContext = () => useContext(ApiContext);

export default useApiContext;
