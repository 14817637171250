import React, {useEffect} from 'react';

import {
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';

import deepEqual from 'deep-equal';

import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from './theme';

import 'react-toastify/dist/ReactToastify.css';

import {type Api} from './createApi';

import Layout from './components/Layout';
import Org, {createLoader as createOrgLoader} from './pages/OrgPage';
import About from './pages/About';
import Login from './pages/Login';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import Logout from './pages/Logout';
import SetNewPassword from './pages/SetNewPassword';

import OrgsList, {
	createLoader as createOrgsListLoader,
} from './pages/OrgsList';

import CoopsList, {
	createLoader as createCoopsListLoader,
} from './pages/CoopsList';

import {createAction as createUser} from './pages/Register';
import {createAction as logUserIn} from './pages/Login';
import {createAction as createCoop} from './components/CoopForm';
import NotFound from './pages/NotFound';
import CartPage from './pages/Cart';
import Funnel2ProductLength from './pages/funnel/Funnel-2-ProductLength';
import Funnel3Country from './pages/funnel/Funnel-3-Country';
import Funnel4Topic from './pages/funnel/Funnel-4-Topic';
import Funnel5Expertise from './pages/funnel/Funnel-5-Expertise';
import Funnel6AdditionalInfo from './pages/funnel/Funnel-6-AdditionalInfo';
import ExpertProfileForm from './pages/ExpertProfileForm';
import ExpertProfile from './pages/ExpertProfilePage';
import CreateOrEditOrgForm from './pages/CreateOrUpdateOrgForm';

import type ApiContextType from './types/ApiContextType';
import {ApiContext} from './ApiContext';
import Home from './pages/Home';
import {type Cart, CartContext} from './CartContext';
import {type Product} from '../common/types/Product';
import {getFromLocalStorage, saveToLocalStorage} from './util';
import type ProductType from '../common/types/ProductType';
import Funnel1ProductType from './pages/funnel/Funnel-1-ProductType';

// eslint-disable-next-line @typescript-eslint/comma-dangle
const areEqual = <T,>(a: T) => (b: T) => {
	return deepEqual(a, b);
};

// @ts-expect-error HMR Runtime causes issues with this router
if (import.meta.hot) {
	// @ts-expect-error HMR Runtime causes issues with this router
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call
	import.meta.hot.dispose(() => router.dispose() as unknown);
}

export const createApp = (api: Api) => {
	/**
	 * Removing this, errors will be handled by the ErrorBoundary
	 * or something else.
	 */
	// api.addErrorListener(message => {
	// 	toast.error(message);
	// });

	const ApiProvider: React.FC<{
		children: React.ReactNode;
	}> = ({children}) => {
		const [apiContext, setApiContext] = React.useState<ApiContextType>({
			api,
		});

		useEffect(() => {
			api.setUserContextUpdater(connectedUserData => {
				setApiContext(prev => {
					if (areEqual(prev.connectedUserData)(connectedUserData.u)) {
						return prev;
					}

					console.log('Connected user data updated:', connectedUserData.u);
					const updatedContext = {
						...prev,
						connectedUserData: connectedUserData.u,
					};

					return updatedContext;
				});
			});
		}, [api]);

		const elt = (
			<ApiContext.Provider value={apiContext}>
				{children}
			</ApiContext.Provider>
		);

		return elt;
	};

	const LoginOrRegister: React.FC<{
		type: 'login' | 'register' | 'forgot-password';
	}> = ({type}) => {
		const [email, onEmailChange] = React.useState('');
		const [password, onPasswordChange] = React.useState('');

		const sharedState = {
			email,
			password,
			onEmailChange,
			onPasswordChange,
		};

		if (type === 'login') {
			return (
				<Login
					{...{...sharedState}}
				/>
			);
		}

		if (type === 'forgot-password') {
			return (
				<ForgotPassword
					{...sharedState}
				/>
			);
		}

		return (
			<Register
				{...sharedState}
			/>
		);
	};

	const loadProductsIfLatestFormat: () => Product[] = () => {
		const maybe = getFromLocalStorage<Product[]>('products', []) ?? [];

		if (maybe.length > 0 && 'fromPages' in maybe[0]) {
			return maybe;
		}

		saveToLocalStorage('products', []);

		return [];
	};

	const loadCurrentProductIfLatestFormat: () => Partial<Product> = () => {
		const maybeCurrent = getFromLocalStorage<Partial<Product>>('current-product', {});

		if (maybeCurrent) {
			return maybeCurrent;
		}

		return {};
	};

	const alterCurrent = (toUpdate: Partial<Product>) => (cart: Cart): Cart => {
		const current = {
			...cart.current,
			...toUpdate,
		};

		saveToLocalStorage('current-product', current);

		return {
			...cart,
			current,
		};
	};

	const CartProvider: React.FC<{
		children: React.ReactNode;
	}> = ({children}) => {
		const [cartContext, setCartContext] = React.useState<Cart>({
			current: loadCurrentProductIfLatestFormat(),
			setType(type: ProductType) {
				setCartContext(alterCurrent({type}));
			},
			setOtherType(type: string) {
				setCartContext(alterCurrent({otherType: type}));
			},
			setPages(fromPages: number, toPages: number) {
				setCartContext(alterCurrent({fromPages, toPages}));
			},
			setCountry(country) {
				setCartContext(alterCurrent({country}));
			},
			setTopic(topic) {
				setCartContext(alterCurrent({topic}));
			},
			setOtherTopic(topic: string) {
				setCartContext(alterCurrent({otherTopic: topic}));
			},
			setExpertise(expertise) {
				setCartContext(alterCurrent({expertise}));
			},
			setOtherExpertise(expertise: string) {
				setCartContext(alterCurrent({otherExpertise: expertise}));
			},
			setAdditionalInfo(additionalInfo: string) {
				setCartContext(alterCurrent({additionalInfo}));
			},
			setAttachment(attachment) {
				setCartContext(alterCurrent({attachment}));
			},
			products: loadProductsIfLatestFormat(),
			addToCart(product: Product) {
				setCartContext(prev => {
					const products = [...prev.products, product];

					saveToLocalStorage('products', products);

					const cart = {
						...prev,
						products,
					};

					return cart;
				});
			},
			removeFromCart(product: Product) {
				setCartContext(
					prev => {
						const products = prev.products.filter(p => p.id !== product.id);

						saveToLocalStorage('products', products);

						const cart = {
							...prev,
							products,
						};

						return cart;
					},
				);
			},
			clearCart() {
				setCartContext(prev => {
					saveToLocalStorage('products', []);
					saveToLocalStorage('current-product', {});

					const cart = {
						...prev,
						products: [],
						current: {},
					};

					return cart;
				});
			},
		});

		return (
			<CartContext.Provider value={cartContext}>
				{children}
			</CartContext.Provider>
		);
	};

	const router = createBrowserRouter([
		{
			path: '/',
			element: (
				<ApiProvider>
					<CartProvider>
						<Layout />
					</CartProvider>
				</ApiProvider>
			),
			children: [
				{
					path: '*',
					element: <NotFound />,
				},
				{
					path: '',
					element: <Home />,
				},
				{
					path: 'product-type',
					element: <Funnel1ProductType />,
				},
				{
					path: 'product-length',
					element: <Funnel2ProductLength />,
				},
				{
					path: 'country',
					element: <Funnel3Country />,
				},
				{
					path: 'topic',
					element: <Funnel4Topic />,
				},
				{
					path: 'expertise',
					element: <Funnel5Expertise />,
				},
				{
					path: 'additional-info',
					element: <Funnel6AdditionalInfo />,
				},
				{
					path: 'edit-profile',
					element: <ExpertProfileForm />,
				},
				{
					path: 'profile/:id',
					element: <ExpertProfile />,
				},
				{
					path: 'requests',
					element: <CartPage />,
				},
				{
					path: 'coops',
					element: <CoopsList />,
					loader: createCoopsListLoader(api),
					action: createCoop(api),
				},
				{
					path: 'orgs',
					element: <OrgsList />,
					loader: createOrgsListLoader(api),
				},
				{
					path: 'orgs/:orgId',
					element: <Org />,
					loader: createOrgLoader(api),
				},
				{
					path: 'my-org',
					element: <CreateOrEditOrgForm />,
				},
				{
					path: 'about',
					element: <About />,
				},
				{
					path: 'login',
					element: <LoginOrRegister type='login' />,
					action: logUserIn(api),
				},
				{
					path: 'forgot-password',
					element: <LoginOrRegister type='forgot-password' />,
				},
				{
					path: 'set-new-password',
					element: <SetNewPassword />,
				},
				{
					path: 'register',
					element: <LoginOrRegister type='register' />,
					action: createUser(api),
				},
				{
					path: 'logout',
					element: <Logout />,
				},
			],
		},
	]);

	const App: React.FC = () =>
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<RouterProvider router={router} />
		</ThemeProvider>;

	return App;
};

export default createApp;
