import {
	useState as nativeUseState,
	useEffect,
	useRef,
	type Dispatch,
	type SetStateAction,
} from 'react';

export const createUseStateGroup = () => {
	const dirtyCount = useRef(0);

	const isPristine = () => dirtyCount.current === 0;
	const isDirty = () => !isPristine();

	const resetInitials = useRef<Array<() => void>>([]);

	const useState = <S>(initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>] => {
		const [value, nativeSetValue] = nativeUseState(initialState);
		const initial = useRef(value);

		useEffect(() => {
			const resetInitial = () => {
				nativeSetValue(s => {
					initial.current = s;
					return s;
				});
			};

			resetInitials.current.push(resetInitial);
		}, [resetInitials.current]);

		const setValue: Dispatch<SetStateAction<S>> = newValue => {
			if (newValue === initial.current) {
				--dirtyCount.current;
			} else {
				++dirtyCount.current;
			}

			nativeSetValue(newValue);
		};

		return [value, setValue];
	};

	const setPristine = () => {
		resetInitials.current.forEach(resetInitial => {
			resetInitial();
		});

		dirtyCount.current = 0;
	};

	return {
		useState,
		isPristine,
		isDirty,
		setPristine,
	};
};

export default createUseStateGroup;
