import React, {useEffect} from 'react';
import {Link, useLoaderData} from 'react-router-dom';
import {toast} from 'react-toastify';
import PlaceIcon from '@mui/icons-material/Place';
import type TopicsWithSubtopics from '../../common/types/TopicsWithSubtopics';

import {
	Button,
	Box,
	Typography,
	Grid,
	Divider,
	Stack,
	type TypographyProps,
	type GridProps,
	List,
} from '@mui/material';

import {
	Web as WebIcon,
} from '@mui/icons-material';

import type Org from '../../common/types/Org';

import {type Api} from '../createApi';
import {isError, showCity} from '../../common/util';
import useApiContext from '../ApiContext';
import type CustomError from '../../common/types/Error';
import Image from '../components/Image';
import useLayout from '../hooks/useLayout';
import type ExpertProfile from '../../common/types/ExpertProfile';
import {createChevronTitle} from '../jsxUtil';
import {getLinkToOrgsWithTopic} from '../urls';
import {showFirstCity} from '../../common/util';

const SmallTitle: React.FC<{
	title: string;
	sx?: TypographyProps;
	color?: string;
}> = ({title, sx, color}) => (
	<Typography variant='h4' sx={{mb: 1, ...sx}} fontSize='small' color={color}>
		{title}
	</Typography>
);

const ExpertCard: React.FC<{
	expert: ExpertProfile;
}> = ({expert}) => (
	<Box style={{textAlign: 'center'}}>
		<Image
			sources={expert.image}
			style={{
				border: '1px solid',
				borderRadius: '50%',
				marginTop: '1em',
				objectFit: 'cover',
				width: '130px',
				height: '130px',
				alignSelf: 'center',
			}}
			sx={{
				maxWidth: '100%',
			}}
		/>
		<Typography variant='h6' style={{textAlign: 'center'}}>
			<Link to={`/profile/${expert.user?.id}`}>
				<strong>{expert.fullName}</strong>
			</Link>
		</Typography>
		<Typography>
			Location
		</Typography>
	</Box>
);

export const createLoader = (api: Api) => async ({params}: any): Promise<Org> =>
	api.getOrganization({id: (params.orgId as string)});

// eslint-disable-next-line complexity
export const OrgPage: React.FC = () => {
	useLayout({
		type: 'wide',
		title: undefined,
	});

	const [org, setOrg] = React.useState<Org | CustomError>(useLoaderData() as Org | CustomError);
	const [subTopics, setSubTopics] = React.useState<TopicsWithSubtopics>([]);
	const {api, connectedUserData} = useApiContext();

	useEffect(() => {
		if (isError(org)) {
			return;
		}

		if (org?.id) {
			api.getOrgTopicsAndSubtopics({orgId: org.id})
				.then((topics: TopicsWithSubtopics) => {
					setSubTopics(topics);
				})
				.catch(err => {
					console.error('Failed to get topics and subtopics', err);
					toast.error('Failed to get topics and subtopics');
				});
		}
	}, [!isError(org) && org.id]);

	/* Do it server side
	useEffect(() => {
		if (isError(org)) {
			return;
		}

		if (org?.id) {
			api.getOrgLanguages({orgId: org.id})
				.then((languages: Language[]) => {
					setLanguages(languages);
				})
				.catch(err => {
					console.error('Failed to get languages', err);
					toast.error('Failed to get languages');
				});
		}
	}, [!isError(org) && org.id]);
	*/

	if (connectedUserData && !connectedUserData.id) {
		const msg = 'Connected user has no `id`, this should never happen, you have found a bug! Please report this to the developers.';
		console.error('error', msg, {user: connectedUserData}, {org});
		throw new Error(msg);
	}

	// TODO: redirect to a proper error page
	if (isError(org)) {
		return (
			<Box>
				<Typography variant='h3'>
					Error
				</Typography>
				<Typography variant='body1'>
					{org.message}
				</Typography>
			</Box>
		);
	}

	const toggleMembership = async () => {
		if (!connectedUserData) {
			return;
		}

		if (!org.id) {
			console.error('No org id, this is weird', {org});
			return;
		}

		const res = await api.toggleExpertMembership({
			userId: connectedUserData.id,
			orgId: org.id,
		}).catch(err => {
			console.error(err);
			toast.error('Failed to toggle membership');
			return false as const;
		});

		if (res) {
			setOrg({
				...org,
				members: res.updatedOrgMembers,
			});
		}
	};

	const thisIsMyOrg = connectedUserData && org.owner?.id === connectedUserData.id;
	const iBelongToThisOrg = connectedUserData && org.members?.some(
		m => m.user.id === connectedUserData.id,
	);

	const experts = (org.members ?? []).map(
		member => member.user.expertProfile,
	).filter(
		(p): p is ExpertProfile => Boolean(p),
	);

	const threeColumnsSectionProps: GridProps = {
		container: true,
		spacing: {
			xs: 4,
			sm: 2,
		},
	};
	const gridColumnProps: GridProps = {
		item: true,
		xs: 12,
		sm: 4,
	};

	return (
		<Box sx={{
			display: 'flex',
			flexDirection: 'column',
			gap: 6,
		}}>
			<Grid container>
				<Grid item xs={12} md={8}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 2,
							my: 2,
						}}
					>
						<Image sources={org.image} />
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 1,
								mb: 0,
							}}
						>
							{createChevronTitle(org.name, {mb: 0})}
							<Stack alignItems='center' direction='row' gap={1}>
								<PlaceIcon color='primary' />
								<Typography>
									{org.countries?.map(c => c.name).join(' - ')}
								</Typography>
							</Stack>
						</Box>
					</Box>
				</Grid>

				<Grid {...threeColumnsSectionProps}>
					<Grid {...gridColumnProps} sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}>
						{org.affiliatedOrgs && org.affiliatedOrgs.length > 0 && (
							<Box sx={{my: 2}}>
								<SmallTitle title='AFFILIATED ORGANIZATIONS' />

								{org.affiliatedOrgs.map(affiliatedOrg => (
									<Box key={affiliatedOrg.id}>
										<Typography variant='h6' color='primary'>
											<Link
												to={`/orgs/${affiliatedOrg.id!}`}
												style={{
													color: 'inherit',
												}}
											>
												<strong>{affiliatedOrg.name}</strong>
											</Link>
										</Typography>
										<Typography variant='body2' color='primary'>{showFirstCity(affiliatedOrg)}</Typography>
									</Box>
								))}
							</Box>
						)}
						<Divider />
						{org.cities && org?.cities.length > 0 && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='HEADQUARTERS' />
								<List>
									{org.cities.map(c => (
										<Typography key={c.id} variant='h6'>
											<strong>{showCity(c)}</strong>
										</Typography>
									))}
								</List>
							</Box>
						)}
						{org.countries && org.countries.length > 0 && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='WORKING IN' />
								{org.countries?.map(country => (
									<Typography key={country.id} variant='h6'>
										<strong>{country.name}</strong>
									</Typography>
								))}
							</Box>
						)}
						{org.languages && (org.languages.length > 0) && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='LANGUAGES' />
								{org.languages.map(language => (
									<Typography variant='h6' key={language.id}>
										<strong>{language.name}</strong>
									</Typography>
								))}
							</Box>
						)}
						{org.expertise && org.expertise.length > 0 && (
							<Box>
								<SmallTitle title='EXPERTISE' />
								{org.expertise.map(expertise => (
									<Typography variant='h6' key={expertise.id}>
										<strong>{expertise.name}</strong>
									</Typography>
								))}
							</Box>
						)}
						<Divider />
						{org.orgType && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='TYPE' />
								<Typography variant='h6'><strong>{org.orgType}</strong></Typography>
							</Box>
						)}
						{org.founders && org.founders.length > 0 && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='FOUNDERS' />
								{org.founders.map(founder => (
									<Typography key={founder.id} variant='h6'>
										<strong>{founder.name}</strong>
									</Typography>
								))}
							</Box>
						)}
						{org.yearFounded && (
							<Box sx={{pb: 3}}>
								<SmallTitle title='YEAR FOUNDED' />
								<Typography variant='h6'>
									<strong>{new Date(org.yearFounded).getFullYear()}</strong>
								</Typography>
							</Box>
						)}
						<Box sx={{pb: 3}}>
							<SmallTitle title='ANNUAL BUDGET' />
							<Typography variant='h6'>
								<strong>{org.annualBudget}</strong>
							</Typography>
						</Box>
					</Grid>
					<Grid {...gridColumnProps}>
						<Box sx={{paddingBottom: '2em'}}>
							<SmallTitle title='TOPICS AND KNOWLEDGE' />
							<Box>
								{subTopics.map(st => (
									<Box key={st.topic.id}>
										<Box>
											{st.topic.name && (
												<Typography variant='h6' color='primary'>
													<strong>
														<Link
															to={getLinkToOrgsWithTopic(st.topic.id!)}
															style={{
																color: 'inherit',
															}}
														>
															{st.topic.name}
														</Link>
													</strong>
												</Typography>
											)}
										</Box>
										<Box>
											{st.subtopics.map(subtopic => (
												<Box key={`${st.topic.id}-${subtopic.id}`}>
													<Typography
														sx={{
															ml: 4,
															mt: 1,
														}}
													>
														◦&nbsp;{subtopic.description}
													</Typography>
												</Box>
											))}
										</Box>
									</Box>
								))}
							</Box>
						</Box>
						<SmallTitle title='POOL OF EXPERTS' />
						{experts.length > 0 && (
							<Box
								sx={{
									display: 'flex',
									gap: 4,
									flexDirection: 'row',
									alignItems: 'flex-start',
								}}
							>
								{experts.map(expert => {
									console.log('expert', expert);
									return (
										<ExpertCard key={expert.id} expert={expert} />
									);
								})}
							</Box>
						)}
					</Grid>

					<Divider sx={{my: 2}} />
					<Grid {...gridColumnProps}>
						<Box sx={{pb: '2em'}}>
							<SmallTitle title='BIO' />
							<Typography sx={{mb: 2}}>{org.description}</Typography>
						</Box>
						{org.website && (
							<Box sx={{pb: '2em'}}>
								<Stack alignItems='center' direction='row' gap={1}>
									<WebIcon color='primary' />
									<Typography color='primary'>{org.website}</Typography>
								</Stack>
							</Box>
						)}
						{org.businessRegistrationLink && (
							<Box sx={{pb: '2em'}}>
								<Stack alignItems='center' direction='row' gap={1}>
									<WebIcon color='primary' />
									<Typography color='primary'>{org.businessRegistrationLink}</Typography>
								</Stack>
							</Box>
						)}
						<Divider sx={{my: 2}} />
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 2,
								}}
							>
								{connectedUserData && (
									<Box>
										{thisIsMyOrg && (
											<Typography variant='caption'>
										You are visiting your own organization, so you cannot leave it.
											</Typography>
										)}
										{iBelongToThisOrg ?? thisIsMyOrg ? (
											<Button
												variant='contained'
												color='primary'
												disabled={thisIsMyOrg}
												onClick={toggleMembership}
											>
												<Typography color='danger'>Leave</Typography>
											</Button>
										) : (
											<Button
												variant='contained'
												color='primary'
												disabled={thisIsMyOrg}
												onClick={toggleMembership}
											>
												<Typography color='danger'>Join</Typography>
											</Button>
										)}
									</Box>
								)}
							<Button
								variant='contained'
								color='secondary'
								sx={{
									borderRadius: '20px',
								}}>
									Send a request
							</Button>
							<Button
									disabled={false}
									href={`mailto:${org.name}`}
									variant='outlined'
									sx={{
										borderRadius: '20px',
										maxWidth: '100%',
									}}>
										Contact
							</Button>
							</Box>
					</Grid>

					<Divider sx={{my:4}} />
					<Typography>
						This organization is a proud member of the Polylat network, a global
						alliance of local knowledge-producing entities. By being part of
						Polylat, this organization contributes its unique expertise and
						insights to a collaborative platform that aims to bridge critical
						knowledge gaps. Together, we provide comprehensive intelligence and
						advisory services to institutions worldwide, ensuring informed
						decision-making and effective policy development.
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default OrgPage;
