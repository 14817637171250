import React, {useState} from 'react';
import {
	Box,
	IconButton,
	TextField,
	Typography,
	Paper,
	type SxProps,
} from '@mui/material';

import {Add as AddIcon, Delete as DeleteIcon} from '@mui/icons-material';

import type OpType from '../../common/types/OpType';

interface HasId {
	id: number;
}

const nextId = (items: HasId[]) =>
	Math.max(...items.map(item => item.id), 0) + 1;

type ConditionalProps<T> = T extends undefined ? Record<string, unknown> : {extraData: T};

type CreateInputProps<ItemType extends HasId, ExtraData = undefined> = {
	getItemId: (item: ItemType) => number;
	getItemLabel: (item: ItemType) => React.ReactNode;
	updateItem: (item: ItemType) => (newValue: string | number) => ItemType;
	createItem: (id: number, value: string | number, extraData?: ExtraData) => ItemType;
};

export const createArrayInputComponent = <ItemType extends HasId, ExtraData = undefined>({
	getItemId,
	getItemLabel,
	updateItem,
	createItem,
}: CreateInputProps<ItemType, ExtraData>) => {
	type ComponentProps = {
		sx?: SxProps;
		label: React.ReactNode;
		items: ItemType[];
		onChange: (newItems: ItemType[], opType?: OpType<ItemType>) => void;
		minimumRequired?: number;
	} & ConditionalProps<ExtraData>;

	const Component: React.FC<ComponentProps> = ({
		label,
		items,
		onChange,
		extraData,
		sx,
		minimumRequired = 0,
	}) => {
		const [newItemText, setNewItemText] = useState('');

		const inputRequired = (i: number) => {
			if (i < minimumRequired) {
				return true;
			}

			return false;
		};

		const addRequired = () => {
			if (items.length < minimumRequired) {
				return true;
			}

			return false;
		};

		const addTheNewItem = () => {
			const newItem = createItem(nextId(items), newItemText, extraData as ExtraData);
			const newItems = [...items, newItem];
			setNewItemText('');
			onChange(newItems, {
				added: newItem,
			});
		};

		const ui = (
			<Paper sx={{p: 2, boxShadow: 'none', ...sx}} >
				<Typography sx={{
					mb: 1,
				}}>
					<strong>{label}</strong>
				</Typography>

				{items.map((item, i) => (
					<Box
						key={getItemId(item)}
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							gap: 1,
						}}
					>
						<TextField
							required={inputRequired(i)}
							value={getItemLabel(item)}
							onChange={e => {
								const changedItem = updateItem(item)(e.target.value);

								const newItems = items.map(original => {
									if (getItemId(original) === getItemId(item)) {
										return changedItem;
									}

									return original;
								});

								onChange(newItems, {
									changed: changedItem,
								});
							}}
							fullWidth
							sx={{
								mb: 1,
							}}
						/>
						<IconButton
							onClick={() => {
								const newItems = items.filter(original => getItemId(original) !== getItemId(item));

								onChange(newItems, {
									removed: item,
								});
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Box>
				))}

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						gap: 1,
					}}
				>
					<TextField
						required={addRequired()}
						label='New item'
						fullWidth
						value={newItemText}
						onChange={e => {
							setNewItemText(e.target.value);
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								addTheNewItem();
								e.preventDefault();
							}
						}}
					/>
					<IconButton
						onClick={addTheNewItem}
					>
						<AddIcon />
					</IconButton>
				</Box>
			</Paper>
		);

		return ui;
	};

	return Component;
};

export default createArrayInputComponent;

