import type Model from './Model';

import type Language from './Language';
// TMP import type Country from './Country';
import type Expertise from './Expertise';
import type Topic from './Topic';

import type User from './User';
import type ImageSourceList from './ImageSourceList';
import {type SubTopicObjectMap} from './SubTopic';
import type City from './City';
import type Country from './Country';

export const maxBioWordCount = 50;

export interface ExpertToCity {
	id?: number;
	rank: number;
	city: City;
}

export interface ExpertToCountry {
	id?: number;
	rank: number;
	country: Country;
}

export interface ExpertProfile extends Model {
	fullName: string;
	languages: Language[];
	areasOfExpertise: Expertise[];
	topicsOfFocus: Topic[];
	subTopics: SubTopicObjectMap;
	linkedInProfile: string;
	bio: string;
	website: string;
	cities: ExpertToCity[];
	countries: ExpertToCountry[];
	user?: User;
	image?: ImageSourceList;
}

export type ExpertProfilePayload = ExpertProfile & {
	citiesPayload: City[];
	imageBase64?: string;
};

export default ExpertProfile;
