import React from 'react';
import {Link} from 'react-router-dom';
import PlaceIcon from '@mui/icons-material/Place';

import {
	Box,
	Grid,
	Button,
	Card,
	CardContent,
	Typography,
	CardActions,
	Stack,
	Chip,
	List,
	Divider,
} from '@mui/material';

import {type Org} from '../../common/types/Org';
import Image from './Image';

const SmallTitle: React.FC<{
	title: string;
	color?: string;
}> = ({title, color}) => (
	<Typography variant='h4' fontSize='small' color={color}>
		{title}
	</Typography>
);

export const OrgCard: React.FC<{
	org: Org;
}> = ({org}) => {
	const ui = (
		<Card
			sx={{
				backgroundColor: '#fff',
				borderRadius: '20px',
				boxShadow: 0,
				border: '1px solid',
			}}
		>
			<Grid container>
				<Grid
					item
					xs={12}
					sm={2}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						p: 1,
						backgroundColor: '#fff',
						border: '0px solid',
						borderTopLeftRadius: {
							xs: '20px',
							sm: '20px',
						},
						borderBottomLeftRadius: {
							xs: '0px',
							sm: '20px',
						},
						borderTopRightRadius: {
							xs: '20px',
							sm: '0',
						},
					}}
				>
					<Image
						sources={org.image}
						style={{
							display: 'flex',
							alignSelf: 'start',
							border: '1px solid',
							borderRadius: '50%',
							marginTop: '1em',
							objectFit: 'cover',
							width: '90px',
							height: '90px',
						}}
						sx={{
							maxWidth: '100%',
							marginLeft: {
								xs: '0',
								sm: '2.5em',
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={10}>
					<CardContent>
						<Stack alignItems='center' direction='row' gap={1}>
							<Typography variant='h5' sx={{mb: 1, mt: 2}}>
								<strong>{org.name}</strong>
							</Typography>
							<Typography color='primary' variant='h5' sx={{mb: 1, mt: 2}}>
								<strong>&gt;</strong>
							</Typography>
						</Stack>
						<Stack alignItems='center' direction='row' gap={1}>
							<PlaceIcon fontSize='small' color='primary' />
							<Typography variant='body2'>
								{org.countries?.map(o => o.name).join(', ')}
							</Typography>
						</Stack>
						<Typography variant='body1' sx={{my: 3, width: '90%'}}>
							{org.description}
						</Typography>
						<Divider variant='middle' sx={{my: 2, width: '70%'}}>
							<Chip label='Data' size='small' />
						</Divider>
						<Stack alignItems='center' direction='row' gap={3}>
							<Box sx={{py: 2}}>
								<SmallTitle title='TOPICS' />
								{(org.topics ?? []).length > 0 && (
									<List>
										{org.topics?.map(t => (
											<Typography variant='h6' key={t.id}>
												<strong>{t.name}</strong>
											</Typography>
										))}
									</List>
								)}
							</Box>
							{org.affiliatedOrgs && org.affiliatedOrgs.length > 0 && (
								<Box sx={{py: 2}}>
									<SmallTitle title='AFFILIATED WITH' />
									<List>
										{org.affiliatedOrgs.map(o => (
											<Typography variant='h6' color='primary' key={o.id}>
												<strong>{o.name}</strong>
											</Typography>
										))}
									</List>
								</Box>
							)}
						</Stack>
						{/*	{(org.expertise ?? []).length > 0 && (
							<List>
								{org?.expertise?.map((ex) => (
									<Chip
										key={ex.id}
										icon={<EngineeringIcon />}
										label={ex.name}
										sx={{ my: 1, mx: 1 }}
										color='primary'
									/>
									// <li key={t.id}><Typography variant='body2'>{t.name}</Typography></li>
								))}
							</List>
						)}
						{/* 						{(org.topics ?? []).length > 0 && (
							<List>
								{org.topics?.map((t) => (
									<Chip
										key={t.id}
										icon={<SubjectIcon />}
										label={t.name}
										sx={{ my: 1, mx: 1 }}
										color='secondary'
									/>
									// <li key={t.id}><Typography variant='body2'>{t.name}</Typography></li>
								))}
							</List>
						)} */}
					</CardContent>
					<CardActions
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							p: 2,
						}}
					>
						<Link to={`/orgs/${org.id}`}>
							<Typography sx={{fontSize: '0.8em', mr: 2}} color='primary'>
								More info
							</Typography>
						</Link>
						<Link to='/product-type'>
							<Button
								size='small'
								variant='contained'
								type='button'
								color='secondary'
								sx={{
									borderRadius: '20px',
								}}
							>
								Send request
							</Button>
						</Link>
					</CardActions>
				</Grid>
			</Grid>
		</Card>
	);

	return ui;
};

export default OrgCard;
