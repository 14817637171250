import type ExpertProfile from './types/ExpertProfile';
import type Org from './types/Org';
import type City from './types/City';
import type Country from './types/Country';

export type Env = 'production' | 'development';

export const env = (): Env => process.env.NODE_ENV === 'production' ? 'production' : 'development';

export const message = (e: unknown): string => {
	if (typeof e === 'string') {
		return e;
	}

	if (e instanceof Error) {
		return e.message;
	}

	return JSON.stringify(e);
};

export const has = (key: string) => (x: unknown): x is Record<string, unknown> =>
	typeof x === 'object' && x !== null && (key in x);

export const isError = (x: unknown): x is {type: 'error'; message?: string} =>
	has('type')(x) && x.type === 'error';

export const isSuccess = (x: unknown): boolean =>
	!isError(x) && Boolean(x);

export const getMessage = (x: unknown, defaultValue: string): string => {
	if (isError(x)) {
		return x.message ?? defaultValue;
	}

	return defaultValue;
};

export const countWords = (s: string): number => s.split(/\s+/).filter(Boolean).length;

export const forEach = <T>(fn: (x: T) => void) => (iterable: Iterable<T>): void => {
	for (const x of iterable) {
		fn(x);
	}
};

export type HumanReadable = {
	name: string;
} | {
	title: string;
} | {
	label: string;
} | {
	description: string;
};

export type IdType = PropertyKey;
export type Identifiable<T> = T & {id: IdType} & HumanReadable;
export type IdGetter<T> = (x: Identifiable<T>) => IdType;
// TMP type Container<T> = Map<PropertyKey, T> | Record<PropertyKey, T> | T[];

export const getId = <T>(x: Identifiable<T>): IdType => x.id;
export const getName = <T>(x: Identifiable<T>): string => {
	if ('name' in x) {
		return x.name;
	}

	if ('title' in x) {
		return x.title;
	}

	if ('label' in x) {
		return x.label;
	}

	if ('description' in x) {
		return x.description;
	}

	throw new Error('No name found on object');
};

export const isIdentifier = <T>(x: unknown): x is Identifiable<T> =>
	['string', 'number', 'symbol'].includes(typeof x) && (Boolean(x) || x === 0);

export const createIterableOfSameType = <T>(specimen: Iterable<Identifiable<T>>) => (items: Iterable<Identifiable<T>>): typeof specimen => {
	if (specimen instanceof Array) {
		return Array.from(items);
	}

	if (specimen instanceof Set) {
		return new Set(items);
	}

	if (specimen instanceof Map) {
		return new Map([...items].map(i => [getId<T>(i), i])) as typeof specimen;
	}

	return (function * () {
		for (const item of items) {
			yield item;
		}
	})();
};

export const mergeArrays = <T>(first: Iterable<Identifiable<T>>) =>
	(...rest: Array<Iterable<Identifiable<T>>>): typeof first => {
		const convertToOutputType = createIterableOfSameType(first);

		if (rest.length === 0) {
			return convertToOutputType(first);
		}

		const map = new Map<PropertyKey, Identifiable<T>>();

		const loop = forEach((x: Identifiable<T>) => {
			map.set(x.id, x);
		});

		const transform = forEach(loop);

		transform([first, ...rest]);

		return convertToOutputType(map.values());
	};

export const asNum = (x: unknown, xName = ''): number | never => {
	if (typeof x === 'number') {
		return x;
	}

	const msg = () => {
		if (xName) {
			return `"${xName}" is not a number (got ${JSON.stringify(x)})`;
		}

		return `Not a number: ${xName}`;
	};

	if (typeof x === 'string') {
		const n = Number(x);

		if (Number.isNaN(n)) {
			throw new Error(msg());
		}

		return n;
	}

	throw new Error(msg());
};

export const asString = (x: unknown, xName = ''): string | never => {
	if (typeof x === 'string') {
		return x;
	}

	const msg = () => {
		if (xName) {
			return `"${xName}" is not a string (got ${JSON.stringify(x)})`;
		}

		return `Not a string: ${xName}`;
	};

	throw new Error(msg());
};

export const asBoolean = (x: unknown, xName = ''): boolean | never => {
	if (typeof x === 'boolean') {
		return x;
	}

	const msg = () => {
		if (xName) {
			return `"${xName}" is not a boolean (got ${JSON.stringify(x)})`;
		}

		return `Not a boolean: ${xName}`;
	};

	throw new Error(msg());
};

export const asDate = (x: unknown, xName = ''): Date | never => {
	if (x instanceof Date) {
		return x;
	}

	if (typeof x === 'string') {
		const d = new Date(x);

		if (Number.isNaN(d.getTime())) {
			throw new Error(`"${xName}" is not a valid date string (got ${JSON.stringify(x)})`);
		}

		return d;
	}

	throw new Error(`"${xName}" is not a date (got ${JSON.stringify(x)})`);
};

export type CityWithCountry = {
	city?: City & {country?: Country};
};

export const showCity = (c: CityWithCountry): string => {
	const {city} = c;

	if (!city) {
		return '<no city>';
	}

	const {name} = city;

	const {country} = city;

	if (country) {
		return `${name}, ${country.name}`;
	}

	return name;
};

export const showFirstCity: (org: Org | ExpertProfile) => string = org => {
	const {cities} = org;

	if (!cities || cities.length === 0) {
		return '<no cities>';
	}

	const [firstCity] = cities;

	return showCity(firstCity);
};
