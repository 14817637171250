import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Typography} from '@mui/material';

import {createTitleElement} from '../jsxUtil';

import useApiContext from '../ApiContext';
import useLayout from '../hooks/useLayout';

export const Logout: React.FC = () => {
	useLayout({
		type: 'three-column',
		title: undefined,
		left: undefined,
	});

	const {api, connectedUserData} = useApiContext();
	const [timeLeft, setTimeLeft] = useState(5);

	const navigate = useNavigate();

	useEffect(() => {
		api.logout();

		const i = setInterval(() => {
			setTimeLeft(prev => prev - 1);
		}, 1000);

		return () => {
			clearInterval(i);
		};
	}, [connectedUserData]);

	useEffect(() => {
		if (timeLeft === 0) {
			navigate('/about');
		}
	}, [timeLeft]);

	console.log('timeLeft', timeLeft);

	return (
		<Box>
			{createTitleElement('You\'ve been logged out.')}
			<Box>
				<Typography>Bye bye, it&apos;s been a pleasure, see you soon!</Typography>
				<Typography>Redirecting in {timeLeft}...</Typography>
			</Box>
		</Box>
	);
};

export default Logout;
