import React from 'react';
import {useNavigate, Link} from 'react-router-dom';

import {
	AppBar,
	Box,
	IconButton,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from '@mui/material';

import {
	Menu as MenuIcon,
} from '@mui/icons-material';

import LoginWidget from './LoginWidget';
import CartWidget from './CartWidget';

export const NavBar: React.FC = () => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
	const navigate = useNavigate();

	const handleClose = () => {
		setAnchorEl(undefined);
	};

	const linkStyle = {
		textDecoration: 'none',
		color: 'inherit',
	};

	const typoSx = {
		cursor: 'pointer',
		color: 'inherit',
		px: '20px',
	};

	const links = (
		<Box sx={{
			display: {
				xs: 'none',
				sm: 'flex',
			},
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			gap: 1,
			mr: 1,
		}}>
			<Link to='/about' style={linkStyle}><Typography sx={typoSx}>About</Typography></Link>
			<Link to='/orgs' style={linkStyle}><Typography sx={typoSx}>Index</Typography></Link>
			<Link to='/' style={linkStyle}><Typography sx={typoSx}>New Request</Typography></Link>
		</Box>
	);

	return (
		<Box sx={{flexGrow: 1}}>
			<AppBar position='static' color='transparent'>
				<Toolbar>
					<IconButton
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{
							mr: 2,
							display: {
								xs: 'block',
								sm: 'none',
							},
						}}
						onClick={event => {
							setAnchorEl(event.currentTarget);
						}}>
						<MenuIcon />
					</IconButton>
					<Menu
						id='menu-appbar'
						anchorEl={anchorEl}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						keepMounted
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem onClick={() => {
							navigate('/orgs');
							handleClose();
						}}>
							Home
						</MenuItem>
						<MenuItem onClick={
							() => {
								navigate('/about');
								handleClose();
							}
						}>
							About
						</MenuItem>
						<MenuItem onClick={
							() => {
								navigate('/orgs');
								handleClose();
							}
						}>
							Index
						</MenuItem>
						<MenuItem onClick={
							() => {
								navigate('/');
								handleClose();
							}
						}>
							New request
						</MenuItem>
					</Menu>
					<Typography
						variant='h6'
						fontWeight={800}
						component='div'
						sx={{
							flexGrow: 1,
							cursor: 'pointer',
						}}
						onClick={() => {
							navigate('/orgs');
						}}
					>
						Polylat&nbsp;Index
					</Typography>
					{links}
					<CartWidget />
					<LoginWidget />
				</Toolbar>
			</AppBar>
		</Box>
	);
};

export default NavBar;
