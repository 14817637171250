import React, {type ComponentProps} from 'react';
import {Typography, type SxProps} from '@mui/material';

import type ImageSourceList from '../../common/types/ImageSourceList';

export const Image: React.FC<{
	sources?: ImageSourceList;
	sx?: SxProps;
	defaultElement?: React.ReactElement;
} & ComponentProps<'img'>> = ({sources, sx, defaultElement, ...otherImgProps}) => {
	if (!sources) {
		return defaultElement ?? <Typography>Image not found</Typography>;
	}

	// D const theme = useTheme();

	const {resized} = sources;

	/* D
	const bps = Object.entries(
		theme.breakpoints.values,
	).slice(1, resized.length + 1).map(([bp, width], i) => ({
		bp,
		width,
		i,
	}));

	const multiplier = (w: number) => (
		Math
			.round(100 * w / sources.original.width) / 100).toFixed(2);

	const srcSetArray = bps.map(
		({i, width}) =>
			`${resized[i].url} ${multiplier(width)}x`,
	);

	if (hd.width > bps[bps.length - 1].width) {
		srcSetArray.push(`${hd.url} ${multiplier(sources.original.width)}x`);
	}

	const srcSet = srcSetArray.join(', ');

	const src = sources.resized[0].url;
	*/

	const src = resized.length > 0 ? resized[0].url : sources.original.url;

	if (!src) {
		return defaultElement ?? <Typography>Image not found</Typography>;
	}

	return (
		<img {...{src, ...otherImgProps}}/>
	);
};

export default Image;
